import useMap from '@/hooks/useMap';
import useAnalytics from '@/hooks/useAnalytics';
import { HEADER } from '@/utils/analyticsClientHelper';
import type { SimpleClickFact } from '@/analytics/models/cventAnalytics/SimpleClickFact';
import { getSimpleClickFact } from '@/analytics/helpers/cventAnalytics/getSimpleClickFact';
import { isValidParam } from '@/utils/urlHelper';
import { useSearchParams } from 'next/navigation';
import { useRouter } from '@/lib/router-events/patch-router/router';
import { queryStringConstants } from '@/constants/queryConstants';
import { useEffect, useState, useCallback, useRef } from 'react';
import type { PlannerAppUrls } from '@/models/PlannerAppUrls';
import Button from '@/components/shared/button/Button';
import dynamic from 'next/dynamic';
import { type FooterProps } from '@/components/legacy/header/cart/footer/ShoppingCartFooter';
import { buildBackUrl } from '@/utils/shoppingCartHelper';
import SimpleClick from '@/analytics/actions/SimpleClick';
import cventAnalytics from '@cvent/analytics';

const PhoenixModal = dynamic(() => import('@/components/legacy/header/cart/PhoenixModal'), { ssr: false });

export default function CartFooterForLoggedInUsers(footerProps: FooterProps): JSX.Element {
  const { cartItems, strings, styles, testID, isPhxEvtContainerExpOn } = footerProps;
  const cartSize = Object.keys(cartItems).length;
  const searchParams = useSearchParams();
  const { mapView } = useMap();
  const analytics = useAnalytics();
  const router = useRouter();
  const filter = searchParams?.get(queryStringConstants.FILTER) ?? '';
  const measurementUnit = searchParams?.get(queryStringConstants.MEASUREMENT_UNIT) ?? '';
  const rfpId = searchParams?.get(queryStringConstants.RFP_ID) ?? '';
  const isValidRfpId = rfpId !== '';
  const isPhoenix = searchParams?.get(queryStringConstants.APP) === 'phoenix';
  const eventId = searchParams?.get(queryStringConstants.EVENT_ID);
  const isEventContext = isPhxEvtContainerExpOn && isPhoenix && isValidParam(eventId);
  const [isPhoenixModalOpen, setPhoenixModalOpen] = useState(false);
  const buttonTriggerRef = useRef<HTMLButtonElement>(null);
  const backUrl = buildBackUrl();
  const [plannerAppUrls, setPlannerAppUrls] = useState<PlannerAppUrls | undefined>();
  const triggerSimpleCartFact = useCallback(
    async (clickEntity: string) => {
      const simpleClickFact: SimpleClickFact = getSimpleClickFact(clickEntity, HEADER, 'null', 'null', mapView, 'null');

      // cvent/analytics
      cventAnalytics.track(new SimpleClick(simpleClickFact));
      await analytics.reportFactImmediately<SimpleClickFact>(simpleClickFact);
    },
    [mapView, analytics]
  );

  const PhoenixPopUpStrings = {
    _create_new_rfp: strings._create_new_rfp,
    _add_to_existing_rfp: strings._add_to_existing_rfp,
    _modal_description: strings._modal_description,
    _add_to_rfp: strings._add_to_rfp,
    _new_rfp: strings._new_rfp,
    _copy_rfp: strings._copy_rfp,
    cancel: strings.cancel,
    next: strings.next,
    _close: strings._close
  };
  useEffect(() => {
    const fetchPlannerAppUrls = async () => {
      const result = await fetch('/venues/api/cart/url', {
        method: 'POST',
        body: JSON.stringify({
          type: 'loggedIn',
          filter,
          measurementUnit,
          rfpId,
          isPhoenix,
          backUrl,
          eventId: isEventContext ? eventId : ''
        })
      });
      if (result.ok) {
        const urls = await result.json();
        setPlannerAppUrls(urls);
      }
    };

    fetchPlannerAppUrls();
  }, [filter, measurementUnit, rfpId, backUrl, isPhoenix, eventId, isEventContext]);

  const onModalClickHandler = useCallback(async () => {
    if (!isPhoenix || isEventContext) {
      await triggerSimpleCartFact('add-to-new-rfp');
      if (plannerAppUrls?.onViewVenueNewRFP) {
        router.push(plannerAppUrls?.onViewVenueNewRFP);
      }
    } else {
      setPhoenixModalOpen(true);
    }
  }, [isPhoenix, isEventContext, plannerAppUrls, router, triggerSimpleCartFact]);

  const onClickHandler = useCallback(
    async (event, type: string, linkToOpen: string | undefined) => {
      event.preventDefault();
      await triggerSimpleCartFact(type);
      if (linkToOpen) {
        window.open(linkToOpen, '_self');
      }
    },
    [triggerSimpleCartFact]
  );

  return (
    <div className={styles?.baseStyle}>
      {!isValidRfpId ? (
        <>
          <Button
            text={strings._create_new_rfp}
            onClick={onModalClickHandler}
            className="mb-2.5"
            testID={`${testID}-add-to-new-rfp`}
            ref={buttonTriggerRef}
            variant={cartSize === 0 ? 'neutral-black' : 'brand'}
            disabled={cartSize === 0}
            isBlock
            appearance="filled"
          />
          {isPhoenixModalOpen && (
            <PhoenixModal
              setPhoenixModalOpen={setPhoenixModalOpen}
              strings={PhoenixPopUpStrings}
              plannerAppUrls={plannerAppUrls}
            />
          )}
          {/*  Don't show Add to Existing RFP CTA if eventId query param is present */}
          {!isEventContext && (
            <a
              href={plannerAppUrls?.onViewVenueAddToExistingRFP}
              onClick={e => onClickHandler(e, 'add-to-existing-rfp', plannerAppUrls?.onViewVenueAddToExistingRFP)}
              className={styles?.linkStyle}
              data-cvent-id={`${testID}-add-to-existing-rfp`}
            >
              <div title={`${strings._add_to_existing_rfp}`} className={styles?.buttonStyle}>
                {strings._add_to_existing_rfp}
              </div>
            </a>
          )}
        </>
      ) : (
        <a
          href={plannerAppUrls?.onViewVenueAddToRFP}
          className={styles?.linkStyle}
          onClick={e => onClickHandler(e, 'add-to-rfp', plannerAppUrls?.onViewVenueAddToRFP)}
          data-cvent-id={`${testID}-add-to-rfp-button`}
        >
          <div className={styles?.buttonStyle}>{strings._add_to_rfp}</div>
        </a>
      )}
      {cartSize > 1 && (
        <a href={plannerAppUrls?.compareVenues} className={styles?.linkStyle}>
          <div
            className="mb-[10px] w-full bg-[#eeeff0] font-[700] text-[#006AE1] hover:text-[#004CD6] hover:no-underline"
            data-cvent-id={`${testID}-compare-venues`}
          >
            {strings.CsnSearchApp_ShoppingCartDropdown_DownloadVenueComparison}
          </div>
        </a>
      )}
    </div>
  );
}
